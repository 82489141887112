$primaryColor: teal;
$secondaryColor: #F50057;


body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color:white;
}

//DataGrid
.MuiDataGrid-virtualScrollerRenderZone {
	border-width:0!important;
}

.MuiDataGrid-cell {
	border-right-width:0!important;
}

.MuiDataGrid-columnSeparator {
	opacity:0!important;
}

.MuiDataGrid-columnHeaderTitle {
	margin:0 auto;
}

.greeting {
	width:100%;
	max-width:600px;
	margin:0 auto;
	margin-bottom:2em;
	padding:2em 1.5em;
	box-sizing:border-box;
	border-top:1px solid silver;
	border-bottom:1px solid silver;
	font-size:90%;
}

.twitterBox {
	text-align:center;
	margin-bottom:6em;
	div {
		font-size:90%;
		margin-top:1em;
		color: teal;
	}
}

.infoBox {
	//background-color:#FFF5F0;
	padding:1em;
	padding-bottom:5em;
	line-height:1.5em;
	position:relative;
	margin-bottom:2em;

	h4 {
		margin:0;
		padding:0;
		margin-bottom:1em;
		color:$secondaryColor;
	}
	ul.fileTypes {
		padding:0;
		margin:0;
		list-style:none;
		li {
			width:50%;
			float:left;
		}
	}
	ul.fileSize {
		padding:0;
		margin:0;
		list-style:none;
		li {
			margin-bottom:0.5em;
			font-size:90%;
		}
	}
	div.impact {
		text-align:center;
		padding:1em 0;
		margin-bottom: 1em;
		border-top:1px solid silver;
		border-bottom:1px solid silver;
	
		font-weight:bold;
		font-size:120%;
		line-height:1em;
	}
	div.actions {
		position:absolute;
		right: 1em;
		bottom: 0.5em;
	}
}

.exampleBox {
	margin-top:3em;
	text-align:center;
	p {
		font-size:90%;
		color: teal;
	}
}

.goAtelier {
	text-align:center;
	margin-top:6em;
	margin-bottom:5em;
	p {
		font-size:90%;
	}
}

.routeBox {
	border:1px solid silver;
	padding:1em;
	height:10em;
	position:relative;
	h5 {
		margin:0;
		padding:0;
		margin-bottom:0.5em;
		color: #333333;
	}
	div {
		font-size:90%;
		color:#666666;
	}
	div.actions {
		position:absolute;
		right: 1em;
		bottom: 0.5em;
	}
}

.routeArrowBox {
	text-align:center;
	padding-top:2.5em;
	font-size:160%;
}

.routeArrowBoxDown {
	text-align:center;
	padding:1em 0;
	font-size:160%;
}

.guideIndex {
	padding: 1em;
	padding-bottom:2em;
	min-height:9em;
	margin-bottom:1em;
	h3 {
		margin:0;
		padding:0;
		line-height:1em;
		font-size:90%;
		margin-bottom:0.5em;
	}
	div {
		font-size:90%;
		color:#666666;
	}
}

.newHelp {
	color: $secondaryColor;
}

.termLead {
	margin-bottom:2em;
}

.termBox {
	margin-bottom:3em;
	font-size:90%;
}

dl.document {
	font-size:90%;
	dt {
		font-weight:bold;
		border-bottom:1px solid silver;
		margin-bottom:0.5em;
	}
	dd {
		margin:0;
		margin-bottom:2em;
	}
}

.error {
	color:red;
}

.requestExamples {
	margin-top:1em;
	padding:1em;
	background-color:#F5F5F5;
	h5 {
		font-size:90%;
		color: teal;
		font-weight:normal;
		margin: 0;
		padding: 0;
		line-height:1em;
	}
	ul {
		padding-left:1.5em;
		li {
			font-size:90%;
		}
	}
}

.link {
	text-decoration:none;
}

.collapseTable {
	border-top:1px solid silver;
	margin: 0;
	padding: 0;
	margin-bottom:1em;
	th {
		background-color: #F5F5F5;		
		white-space:nowrap;
	}
	td.status {
		font-size:90%;
	}
	.icon {
		vertical-align:bottom;
		margin-right:0.5em;
	}
}

td.status0 {
	color: #000033;
}
td.status1 {
	color: teal;
}
td.status9 {
	color: red;
}

.noRecord {
	text-align:center;
	padding:2em 0;
	color:#666666;
}

.transferDone {
	color:#333333;
}

.transferBefore {
	color:#F50057;
}

.transferAfter {
	color:teal;
}

.transferDeposit {
	font-size:120%;
	color:teal;
}

.transferDetailTableTh {
	background-color: #F5F5F5;		
	white-space:nowrap;
}

.chargeDetailRow {
	position: relative;
	h3 {
		position:absolute;
		top:0;
		left:0;
		margin: 0;
		font-size:100%;
		color:#666666;
		font-weight:normal;
		padding-left:1em;
	}
	div {
		text-align:right;
		font-size:100%;
		padding-right:1em;
	}
}

.chargeDetailRowLarge {
	background-color:#F5F5F5;
	padding:1em;
	margin-bottom:2em;
	border-bottom:1px solid #CCCCCC;
	h3 {
		position:relative;
		padding-left:0;
		span {
			font-size:80%;
			color:#999999;
		}
	}
	div {
		margin-top:0.5em;
		padding-right:0;
		font-size:200%;
		line-height:1em;
	}
}